.landing-section
{
    .section-icon {
        color: #cca24f;
        font-size: 3em;
    }
    .jo-page-header {
        margin-bottom: 20px;
        @media(min-width:992px) {
            border-radius: 0 0 63px 63px;
        }
    }
}
